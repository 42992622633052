import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Card, Table, Badge } from "react-bootstrap";
import { FaBell, FaDoorOpen, FaSignOutAlt } from "react-icons/fa";
import "./DashboardComponent.css";
import { Link } from "react-router-dom";
import axios from "axios";
import UserContext from "../../contexts/UserContext";

const CaretakerDashboard = () => {
  const { user } = useContext(UserContext);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [vacantRoomsCount, setVacantRoomsCount] = useState(0);
  const [tenantsMovingOutCount, setTenantsMovingOutCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [arrearsData, setArrearsData] = useState([]);

  // Fetch data from backend
  useEffect(() => {
    const fetchData = async () => {
      try {
        const notificationsResponse = await axios.get(
          "https://noidhousingbackend.mathenoid.com/notifications/caretaker/count"
        );
        const vacantRoomsResponse = await axios.get(
          "https://noidhousingbackend.mathenoid.com/vacantrooms/count"
        );
        const tenantsMovingOutResponse = await axios.get(
          "https://noidhousingbackend.mathenoid.com/movingout/count"
        );
        const notificationsDataResponse = await axios.get(
          "https://noidhousingbackend.mathenoid.com/notifications/caretaker"
        );
        const response = await axios.get(
          "https://noidhousingbackend.mathenoid.com/api/arrears"
        );

        setArrearsData(response.data.arrears.slice(0, 4)); // Limit to four rows        setNotificationsCount(notificationsResponse.data.total);
        setVacantRoomsCount(vacantRoomsResponse.data.total);
        setTenantsMovingOutCount(tenantsMovingOutResponse.data.total);
        setNotifications(notificationsDataResponse.data.slice(0, 4)); // Get the latest 4 notifications
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <Container className="mt-4">
      <Card className="mb-4 card-shadow bg-primary-gradient text-white">
        <Card.Body>
          <Card.Title>Welcome back, {user}</Card.Title>
          <Card.Text className="text-light">
            A simple yet elegant digital solution!
          </Card.Text>
        </Card.Body>
      </Card>

      <Row className="mt-4">
        <Col md={4}>
          <Link to="/Notifications">
            <Card className="mb-4 card-shadow bg-info-gradient text-white hover-focus">
              <Card.Body>
                <Card.Title>
                  <FaBell className="me-2" /> Notifications
                </Card.Title>
                <Card.Text>
                  <Badge bg="light" text="dark" pill>
                    {notificationsCount}
                  </Badge>
                </Card.Text>
              </Card.Body>
            </Card>
          </Link>
        </Col>
        <Col md={4}>
          <Link to="/VacantRooms">
            <Card className="mb-4 card-shadow bg-success-gradient text-white hover-focus">
              <Card.Body>
                <Card.Title>
                  <FaDoorOpen className="me-2" /> Vacant Rooms
                </Card.Title>
                <Card.Text>
                  <Badge bg="light" text="dark" pill>
                    {vacantRoomsCount}
                  </Badge>
                </Card.Text>
              </Card.Body>
            </Card>
          </Link>
        </Col>
        <Col md={4}>
          <Link to="/VacantRooms">
            <Card className="mb-4 card-shadow bg-warning-gradient text-white hover-focus">
              <Card.Body>
                <Card.Title>
                  <FaSignOutAlt className="me-2" /> Tenants Moving Out
                </Card.Title>
                <Card.Text>
                  <Badge bg="light" text="dark" pill>
                    {tenantsMovingOutCount}
                  </Badge>
                </Card.Text>
              </Card.Body>
            </Card>
          </Link>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col md={6}>
          <Link to="/Notifications">
            <Card className="mb-4 card-shadow bg-light-gradient hover-focus">
              <Card.Body>
                <Card.Title>New Notifications</Card.Title>
                <Table striped hover responsive className="mt-3">
                  <thead className="table-primary-gradient">
                    <tr>
                      <th>Date</th>
                      <th>From</th>
                    </tr>
                  </thead>
                  <tbody>
                    {notifications.map((notification, index) => (
                      <tr key={index}>
                        <td>
                          {new Date(notification.date).toLocaleDateString()}
                        </td>
                        <td>{notification.source}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Link>
        </Col>
        <Col md={6}>
          <Link to="/Arrears">
            <Card className="mb-4 card-shadow bg-light-gradient hover-focus">
              <Card.Body>
                <Card.Title>Arrears</Card.Title>
                <Table striped hover responsive className="mt-3">
                  <thead className="table-primary-gradient">
                    <tr>
                      <th>Payment</th>
                      <th>Room</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {arrearsData.map((arrearsData, index) => (
                      <tr key={index}>
                        <td>{arrearsData.type}</td>
                        <td>{arrearsData.room}</td>
                        <td>{arrearsData.amount}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

export default CaretakerDashboard;
