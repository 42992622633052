import React, { useState, useCallback, useEffect } from "react";

import UserContext from "./UserContext";

const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState("");
  const [newNotifications, setNewNotifications] = useState(0);

  const fetchNotificationsCount = useCallback(async () => {
    try {
      const response = await fetch(
        "http://localhost:3000/notifications/caretaker/count/today"
      );
      const data = await response.json();
      setNewNotifications(data.total);
    } catch (error) {
      console.error("Error fetching notifications count:", error);
    }
  }, []);

  useEffect(() => {
    fetchNotificationsCount();
  }, [fetchNotificationsCount]);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        newNotifications,
        setNewNotifications,
        fetchNotificationsCount,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
