import React from "react";
import "./TermsConstitution.css";

const RentalConstitution = () => {
  return (
    <div className="constitution-container">
      <h1 className="heading">Constitution of MATHENOID HOUSING</h1>

      <p className="section">
        <b className="section-heading">Preamble</b>
        <br />
        We, the members of NOID RENTAL MANAGEMENT, in order to facilitate fair
        and efficient rental transactions, promote transparency, ensure the
        protection of both tenants and landlords, and foster a thriving rental
        market, do hereby establish this Constitution for the governance and
        operation of our rental management platform.
      </p>

      <p className="section">
        <b className="section-heading">Article I: Name and Purpose</b>
        <br />
        <b>1.1 Name</b>
        <br />
        The name of the organization shall be NOID RENTAL MANAGEMENT
        (hereinafter referred to as "the Platform").
        <br />
        <b>1.2 Purpose</b>
        <br />
        The purpose of the Platform is to:
        <ul>
          <li>Facilitate rental transactions between tenants and landlords.</li>
          <li>
            Provide a platform for transparent communication and document
            management.
          </li>
          <li>
            Ensure the protection of the rights and interests of both tenants
            and landlords.
          </li>
          <li>Promote fair and efficient rental practices.</li>
          <li>
            Foster a thriving rental market by connecting tenants with suitable
            properties and landlords with reliable tenants.
          </li>
        </ul>
      </p>

      <p className="section">
        <b className="section-heading">Article II: Membership</b>
        <br />
        <b>2.1 Eligibility</b>
        <br />
        Membership is open to individuals and organizations that are either
        tenants or landlords and support the mission and objectives of the
        Platform.
        <br />
        <b>2.2 Application</b>
        <br />
        Prospective members must submit an application to the Platform's
        management. Membership approval is at the discretion of the management.
        <br />
        <b>2.3 Rights and Responsibilities</b>
        <br />
        Members have the right to:
        <ul>
          <li>Access and use the Platform's services.</li>
          <li>
            Attend and participate in meetings and events organized by the
            Platform.
          </li>
          <li>
            Vote on matters presented to the membership, as per the voting
            rights outlined in this Constitution.
          </li>
          <li>Access and manage their rental listings and profiles.</li>
          <li>
            Receive notifications and updates regarding their rental
            transactions.
          </li>
        </ul>
        Members are responsible for:
        <ul>
          <li>
            Complying with all applicable laws, regulations, and this
            Constitution.
          </li>
          <li>
            Paying any fees or charges associated with the use of the Platform's
            services.
          </li>
          <li>
            Acting in good faith and treating other members with respect and
            fairness.
          </li>
          <li>
            Providing accurate and up-to-date information in their member
            profile and rental listings.
          </li>
          <li>
            Responding to inquiries and messages from other members in a timely
            and professional manner.
          </li>
          <li>
            Cooperating with the Platform's management in resolving any disputes
            that may arise.
          </li>
        </ul>
      </p>

      <p className="section">
        <b className="section-heading">Article III: Platform Management</b>
        <br />
        <b>3.1 Management Structure</b>
        <br />
        The Platform shall be managed by a team consisting of:
        <ul>
          <li>
            A Chief Executive Officer (CEO), responsible for overall management
            and strategic direction.
          </li>
          <li>
            A Chief Technology Officer (CTO), responsible for the development
            and maintenance of the Platform's technology infrastructure.
          </li>
          <li>
            A Chief Financial Officer (CFO), responsible for financial
            management and reporting.
          </li>
          <li>
            Other officers and staff as needed to support the Platform's
            operations.
          </li>
        </ul>
        <br />
        <b>3.2 Appointment and Term</b>
        <br />
        The management team shall be appointed by the Board of Directors for a
        term of [number] years. Management team members may serve a maximum of
        [number] consecutive terms.
        <br />
        <b>3.3 Powers and Duties</b>
        <br />
        The management team shall:
        <ul>
          <li>Oversee the day-to-day operations of the Platform.</li>
          <li>
            Implement policies and procedures approved by the Board of
            Directors.
          </li>
          <li>
            Prepare and submit reports to the Board of Directors and the
            membership.
          </li>
          <li>
            Ensure the Platform's compliance with legal and regulatory
            requirements.
          </li>
          <li>Manage the Platform's budget and financial resources.</li>
          <li>Promote the Platform's mission, objectives, and values.</li>
        </ul>
        <br />
        <b>3.4 Conditions and Obligations</b>
        <br />
        The management team shall:
        <ul>
          <li>Act in the best interests of the Platform and its members.</li>
          <li>Exercise due diligence in the performance of their duties.</li>
          <li>
            Disclose any potential conflicts of interest and refrain from
            participating in decisions where a conflict exists.
          </li>
          <li>Respect the confidentiality of sensitive information.</li>
          <li>
            Cooperate with the Board of Directors and the membership in
            achieving the Platform's goals.
          </li>
        </ul>
      </p>

      <p className="section">
        <b className="section-heading">Article IV: Board of Directors</b>
        <br />
        <b>4.1 Composition</b>
        <br />
        The Platform shall be governed by a Board of Directors (hereinafter
        referred to as "the Board") consisting of [number] members, including
        the CEO and other independent directors.
        <br />
        <b>4.2 Election and Term</b>
        <br />
        Board members shall be elected by the membership at the Annual General
        Meeting (AGM) for a term of [number] years. Board members may serve a
        maximum of [number] consecutive terms.
        <br />
        <b>4.3 Powers and Duties</b>
        <br />
        The Board shall:
        <ul>
          <li>Oversee the management and operations of the Platform.</li>
          <li>Set strategic direction and approve policies and procedures.</li>
          <li>Approve the annual budget and financial statements.</li>
          <li>
            Ensure the Platform's compliance with legal and regulatory
            requirements.
          </li>
          <li>Appoint and remove the management team, as necessary.</li>
          <li>Promote the Platform's mission, objectives, and values.</li>
        </ul>
        <br />
        <b>4.4 Conditions and Obligations</b>
        <br />
        The Board shall:
        <ul>
          <li>Act in the best interests of the Platform and its members.</li>
          <li>Exercise due diligence in the performance of their duties.</li>
          <li>
            Disclose any potential conflicts of interest and refrain from
            participating in decisions where a conflict exists.
          </li>
          <li>Respect the confidentiality of sensitive information.</li>
          <li>
            Attend and participate in Board meetings and other relevant events.
          </li>
          <li>
            Cooperate with the management team and the membership in achieving
            the Platform's goals.
          </li>
        </ul>
      </p>

      <p className="section">
        <b className="section-heading">Article V: Meetings</b>
        <br />
        <b>5.1 Annual General Meeting (AGM)</b>
        <br />
        The AGM shall be held annually to:
        <ul>
          <li>Elect Board members.</li>
          <li>Review the annual report and approve financial statements.</li>
          <li>
            Discuss and vote on other important matters, as outlined in this
            Constitution.
          </li>
        </ul>
        <br />
        <b>5.2 Board Meetings</b>
        <br />
        The Board shall meet at least [number] times per year. Special meetings
        may be called by the Chairperson or a majority of Board members.
        <br />
        <b>5.3 Quorum</b>
        <br />
        A quorum for the AGM and Board meetings shall be a majority of the
        members present.
        <br />
        <b>5.4 Notice of Meetings</b>
        <br />
        Notice of meetings shall be provided to members at least [number] days
        in advance, either electronically or in writing.
      </p>

      <p className="section">
        <b className="section-heading">Article VI: Financial Management</b>
        <br />
        <b>6.1 Fiscal Year</b>
        <br />
        The fiscal year of the Platform shall begin on [date] and end on [date].
        <br />
        <b>6.2 Financial Oversight</b>
        <br />
        The Board shall ensure proper financial management, including the
        preparation and approval of an annual budget, financial statements, and
        the appointment of an independent auditor.
        <br />
        <b>6.3 Banking</b>
        <br />
        All funds of the Platform shall be deposited in accounts designated by
        the Board. Authorized signatories for banking transactions shall be
        determined by the Board.
        <br />
        <b>6.4 Fees and Charges</b>
        <br />
        The Platform may charge fees and charges for its services, as determined
        by the Board.
      </p>

      <p className="section">
        <b className="section-heading">Article VII: Dispute Resolution</b>
        <br />
        <b>7.1 Mediation</b>
        <br />
        In the event of a dispute between members or between a member and the
        Platform, the parties shall first attempt to resolve the dispute through
        mediation. The Platform shall provide mediation services or assist the
        parties in finding a suitable mediator.
        <br />
        <b>7.2 Arbitration</b>
        <br />
        If the dispute cannot be resolved through mediation, the parties may
        submit the dispute to arbitration, as outlined in this Constitution or
        as otherwise agreed upon by the parties.
      </p>

      <p className="section">
        <b className="section-heading">Article VIII: Amendments</b>
        <br />
        <b>8.1 Proposal of Amendments</b>
        <br />
        Amendments to this Constitution may be proposed by the Board or by a
        petition signed by at least [number] members.
        <br />
        <b>8.2 Approval of Amendments</b>
        <br />
        Amendments must be approved by a two-thirds majority of members present
        at the AGM or a special meeting called for that purpose.
      </p>

      <p className="section">
        <b className="section-heading">Article IX: Dissolution</b>
        <br />
        <b>9.1 Dissolution Procedure</b>
        <br />
        In the event of dissolution, the assets of the Platform shall be
        distributed to organizations with similar objectives, as determined by
        the Board and approved by the membership.
      </p>

      <p className="section">
        <b className="section-heading">Article X: Miscellaneous</b>
        <br />
        <b>10.1 Non-Discrimination</b>
        <br />
        The Platform shall not discriminate on the basis of race, color,
        religion, gender, sexual orientation, national origin, age, disability,
        or any other characteristic protected by law.
        <br />
        <b>10.2 Confidentiality</b>
        <br />
        The Platform shall maintain the confidentiality of sensitive
        information, as required by law and as outlined in this Constitution.
        <br />
        <b>10.3 Indemnification</b>
        <br />
        The Platform shall indemnify its management team, Board members, and
        other volunteers for any liabilities incurred in the performance of
        their duties, as permitted by law.
      </p>

      <p className="section">
        <b className="section-heading">Adoption</b>
        <br />
        This Constitution was adopted by the membership on [date].
      </p>

      <p className="signatures">
        _____________________________
        <br />
        [Name], Chairperson
        <br />
        <br />
        _____________________________
        <br />
        [Name], Secretary
        <br />
        <br />
        _____________________________
        <br />
        Date
      </p>
    </div>
  );
};

export default RentalConstitution;
