import React, { useState, useEffect } from "react";
import { Table, Container } from "react-bootstrap";
import axios from "axios";
import "./VacantRoomsComponent.css";

const VacantRoomsTable = () => {
  const [rooms, setRooms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRooms = async () => {
      try {
        const response = await axios.get(
          "https://noidhousingbackend.mathenoid.com/vacantrooms"
        );
        setRooms(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchRooms();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  // Helper function to format the date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(); // Format the date as YYYY/MM/DD
  };

  return (
    <Container className="mt-5">
      <h2 className="text-start mb-4">Vacant Rooms</h2>
      <Table responsive className="table-sm elegant-table">
        <thead>
          <tr>
            <th>Serial Number</th>
            <th>Date</th>
            <th>Room</th>
            <th>Type</th>
            <th>Rent</th>
            <th>Deposit</th>
          </tr>
        </thead>
        <tbody>
          {rooms.map((vacantRoom, index) => (
            <tr key={index}>
              <td>{index + 1}</td> {/* Assuming serial number is index + 1 */}
              <td>{formatDate(vacantRoom.date)}</td>
              <td>{vacantRoom.room}</td>
              <td>{vacantRoom.type}</td>
              <td>{vacantRoom.rent}</td>
              <td>{vacantRoom.deposit}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default VacantRoomsTable;
