import React, { useEffect, useState } from "react";
import axios from "axios";
import Table from "react-bootstrap/Table";
import AddTenantButton from "../AddTenantButton/AddTenantButton";
import RemoveTenantButton from "../RemoveTenantButton/RemoveTenantButton";
import "./TenantsComponent.css";

const TenantsTable = () => {
  const [tenantsData, setTenantsData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTenants = async () => {
      try {
        const response = await axios.get(
          "https://noidhousingbackend.mathenoid.com/tenantswithroomtype"
        );
        console.log("Tenants response:", response.data);
        if (Array.isArray(response.data)) {
          setTenantsData(response.data);
        } else {
          throw new Error("Invalid data format");
        }
      } catch (error) {
        console.error("Error fetching tenants:", error);
        setError("Error fetching tenants data");
      }
    };

    fetchTenants();
  }, []);

  // Sort tenants by room in ascending order
  const sortedTenants = [...tenantsData].sort((a, b) =>
    a.room > b.room ? 1 : -1
  );

  return (
    <>
      <div className="printerr">
        <div className="printed">
          <button
            className="btn btn-sm btn-success text-nowrap"
            onClick={() => window.print()}
          >
            Print Tenants
          </button>
          <AddTenantButton />
          <RemoveTenantButton />
        </div>
      </div>
      <div className="mt-5">
        <h2>Tenants Table</h2>
        {error ? (
          <div className="error">{error}</div>
        ) : (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Room</th>
                <th>Name</th>
                <th>Type</th>
                <th>ID Number</th>
                <th>Phone Number</th>
                <th>WhatsApp Number</th>
                <th>Moved In</th>
              </tr>
            </thead>
            <tbody>
              {sortedTenants.map((tenant) => (
                <tr key={tenant.id}>
                  <td>{tenant.room}</td>
                  <td>{tenant.name}</td>
                  <td>{tenant.type}</td>
                  <td>{tenant.idNo}</td>
                  <td>
                    <a
                      className="phoneNoDecoration"
                      target="_blank"
                      rel="noreferrer"
                      href={`tel:${tenant.phoneNumber}`}
                    >
                      {tenant.phoneNumber}
                    </a>
                  </td>
                  <td>
                    <a
                      className="phoneNoDecoration"
                      target="_blank"
                      rel="noreferrer"
                      href={`https://wa.me/+254${tenant.whatsappNumber.slice(
                        1
                      )}`}
                    >
                      {tenant.whatsappNumber}
                    </a>
                  </td>
                  <td>{new Date(tenant.date).toLocaleDateString()}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>
    </>
  );
};

export default TenantsTable;
