import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import axios from "axios";

const ArrearsTable = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [showAllAlert, setShowAllAlert] = useState(false);
  const [arrearsData, setArrearsData] = useState([]);
  const [tenantsData, setTenantsData] = useState([]);

  useEffect(() => {
    const fetchArrearsData = async () => {
      try {
        const response = await axios.get(
          "https://noidhousingbackend.mathenoid.com/api/arrears"
        );
        setArrearsData(response.data.arrears);
      } catch (error) {
        console.error("Error fetching arrears data:", error);
      }
    };

    const fetchTenantsData = async () => {
      try {
        const response = await axios.get(
          "https://noidhousingbackend.mathenoid.com/tenants"
        );
        setTenantsData(response.data);
      } catch (error) {
        console.error("Error fetching tenants data:", error);
      }
    };

    fetchArrearsData();
    fetchTenantsData();
  }, []);

  const aggregateArrears = (room) => {
    return arrearsData
      .filter((arrear) => arrear.room === room)
      .reduce((total, arrear) => total + parseFloat(arrear.amount), 0);
  };

  const handleNotify = async (room) => {
    try {
      const response = await axios.post(
        "https://noidhousingbackend.mathenoid.com/api/tenant/phone",
        { room }
      );

      if (response.data.success) {
        const phoneNumber = response.data.phoneNumber;
        const tenant = tenantsData.find((t) => t.room === room);
        const totalArrears = aggregateArrears(room);
        const message = `Hello ${tenant.name}, please settle the arrears: ${totalArrears}. Thank you.`;

        await axios.post("https://noidhousingbackend.mathenoid.com/sendsms", {
          message,
          mobile: phoneNumber,
        });

        setAlertMessage(`${tenant.name} has been notified via SMS.`);
      } else {
        setAlertMessage("Failed to send SMS.");
      }
    } catch (error) {
      console.error("Error handling notification:", error);
      setAlertMessage("An error occurred while processing the notification.");
    } finally {
      setShowAlert(true);
      setTimeout(() => setShowAlert(false), 5000); // Close alert after 5 seconds
    }
  };

  const handleNotifyAll = async () => {
    try {
      const uniqueRooms = [
        ...new Set(arrearsData.map((arrear) => arrear.room)),
      ];

      for (const room of uniqueRooms) {
        await handleNotify(room);
      }

      setAlertMessage("All members have been notified via SMS.");
    } catch (error) {
      console.error("Error handling notifications:", error);
      setAlertMessage("Some members could not be notified via SMS.");
    } finally {
      setShowAllAlert(true);
      setTimeout(() => setShowAllAlert(false), 5000); // Close alert after 5 seconds
    }
  };

  return (
    <div>
      <h2 className="text-center mt-4 mb-4">ARREARS</h2>
      {/* Alert for individual notification */}
      <Alert
        show={showAlert}
        variant={alertMessage.includes("Failed") ? "danger" : "success"}
        onClose={() => setShowAlert(false)}
        dismissible
      >
        {alertMessage}
      </Alert>
      {/* Alert for Notify All */}
      <Alert
        show={showAllAlert}
        variant={alertMessage.includes("Failed") ? "danger" : "success"}
        onClose={() => setShowAllAlert(false)}
        dismissible
      >
        {alertMessage}
      </Alert>
      <Table striped hover>
        <thead>
          <tr>
            <th>Serial</th>
            <th>Date</th>
            <th>Room</th>
            <th>Tenant Name</th>
            <th>Amount</th>
            <th>Notify</th>
          </tr>
        </thead>
        <tbody>
          {arrearsData.map((row, index) => {
            const tenant = tenantsData.find((t) => t.room === row.room);
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{new Date(row.date).toLocaleDateString()}</td>
                <td>{row.room}</td>
                <td>{tenant ? tenant.name : "Unknown"}</td>
                <td>{row.amount}</td>
                <td>
                  <Button variant="info" onClick={() => handleNotify(row.room)}>
                    Notify
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <div className="text-center mt-3">
        <Button variant="success" onClick={handleNotifyAll}>
          Notify All
        </Button>
      </div>
    </div>
  );
};

export default ArrearsTable;
